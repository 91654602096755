<template>
  <div>
    <base-card v-if="item == 7">
      <v-card-text>
        <v-card-text>
          <div class="mb-5"><span class="text-h6">Restaurant Table</span></div>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" lg="3"
              ><v-text-field
                class="mt-n5"
                v-model="searchBranch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="branchList"
            :search="searchBranch"
            @click:row="handleClick"
          >
          </v-data-table>
        </v-card-text>
      </v-card-text>
    </base-card>
    <v-dialog v-model="dialog" width="800">
      <base-card>
        <v-card-text>
          <div class="mb-5">
            <span class="text-h6">{{ branchName }}'s Table</span>
          </div>

          <v-row>
            <v-col cols="12" lg="8">
              <v-btn
                class="mr-2"
                small
                color="primary"
                @click="(title = 'Add'), openDialog()"
                dark
              >
                Add Table
              </v-btn>
              <v-btn small outlined color="primary" @click="printContent" dark>
                Print Table QR code
              </v-btn>
            </v-col>
            <v-col cols="12" lg="4"
              ><v-text-field
                class="mt-n5"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            :headers="tableHeader"
            :items="tableList"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            item-key="table_id"
            @page-count="pageCount = $event"
            :search="search"
            hide-default-footer
            @click:row="handleEditTable"
            show-select
          >
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-card-text>
      </base-card>
    </v-dialog>

    <v-dialog v-model="dialogTable" width="400">
      <base-card>
        <v-card-text class="mt-3">
          <div class="mb-5">
            <span class="text-h6">{{ title }} Table</span>
          </div>
          <v-text-field
            prepend-icon="mdi-table-furniture"
            label="Table number"
            v-model="tNumber"
            :error-messages="tNumberErrors"
            required
            @input="$v.tNumber.$touch()"
            @blur="$v.tNumber.$touch()"
          ></v-text-field>

          <v-text-field
            prepend-icon="mdi-seat"
            label="Seats"
            v-model="tSeats"
            type="number"
            :error-messages="tSeatsErrors"
            required
            @input="$v.tSeats.$touch()"
            @blur="$v.tSeats.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="title == 'Edit'"
            @click="dialogDelete = true"
            text
            icon
            color="red lighten-2"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogAction" text> save </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-subtitle-1"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" @click="deleteTable()" text>OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-show="false" id="printable-content">
      <div
        style="
          display: grid;
          grid-template-columns: auto auto;
          grid-auto-rows: 450px;
          grid-gap: 10px;
        "
      >
        <div v-for="item in selected" :key="item.table_url">
          <div
            style="
              text-align: center;
              padding: 20px 0;
              font-size: 18px;
              height: auto;
            "
          >
            <vue-qr
              style="border: 3px solid grey"
              :text="QRlink + item.table_url"
              :logoSrc="logo"
              logoBackgroundColor="#00000000"
              :size="250"
            ></vue-qr>
            <br />
            <br />
            <span style="font-weight: bold; font-size: 24px"
              >Table No: {{ item.number }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, minValue, numeric,alphaNum, sameAs,not } from "vuelidate/lib/validators";
import VueQr from "vue-qr";

export default {
  props: {
    item: {
      type: Number,
      default: "",
    },
  },
  mixins: [validationMixin],
  components: { VueQr },
  validations: {
    tNumber: { required,sameAs: not(sameAs('zero')), alphaNum },
    tSeats: { required, minValue: minValue(1) , numeric },
  },

  data: () => ({
    domain: BASEURL,
    syncDomain: BASESYNCURL,
    snackbar: false,
    text: "",
    snackbarColor: "",
    timeout: 1900,
    companyID: "",
    branchList: [],
    tableList: [],
    selected: [],
    title: "",
    dialog: false,
    search: "",
    searchBranch: "",
    dialogTable: false,
    tNumber: "",
    tSeats: "",
    branchURL: "",
    logo: "https://api.optimy.com.my/asset/logo.png",
    page: 1,
    pageCount: 0,
    zero:"0",
    itemsPerPage: 10,
    dialogQR: false,
    dialogDelete: false,
    branchID: "",
    branchName: "",
    selectTable: "",
    headers: [
      {
        text: "Branch",
        align: "start",
        value: "name",
        sortable: false,
      },
      {
        text: "Total table",
        align: "center",
        value: "numberTable",
        sortable: false,
      },
    ],
    tableHeader: [
      {
        text: "Table number#",
        align: "start",
        value: "number",
        sortable: false,
      },
      {
        text: "Seats",
        align: "start",
        value: "seats",
        sortable: false,
      },
    ],
  }),
  computed: {
    QRlink() {
      // ----production----
      var link = "https://qr.optimy.com.my/" + this.branchURL + "/";
      // ----testing----
      // var link = "https://pos-qr.lkmng.com/" + this.branchURL + "/";
      return link;
    },
    tNumberErrors() {
      const errors = [];
      if (!this.$v.tNumber.$dirty) return errors;
      !this.$v.tNumber.required && errors.push("Table Number is required");
      !this.$v.tNumber.alphaNum && errors.push("Table number cannot be negative");
      !this.$v.tNumber.sameAs && errors.push("Table number cannot be 0");
      return errors;
    },

    tSeatsErrors() {
      const errors = [];
      if (!this.$v.tSeats.$dirty) return errors;
      !this.$v.tSeats.minValue && errors.push("Must be positive and not 0");
      !this.$v.tSeats.required && errors.push("Seats is required");
      !this.$v.tSeats.numeric && errors.push("Seats must be integer");
      return errors;
    },
  },

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getBranchAndTableNum();
  },
  methods: {
    printContent() {
      const printableContent = document.getElementById("printable-content");
      const printWindow = window.open("", "", "height=1000,width=1000");
      printWindow.document.write(printableContent.innerHTML);
      printWindow.print();
    },
    getBranchAndTableNum() {
      const params = new URLSearchParams();
      params.append("getBranchAndTableNum", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/table/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.table;
          } else {
            console.log("no list get");
            this.branchList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllTable() {
      const params = new URLSearchParams();
      params.append("getAllTable", "done");
      params.append("branch_id", this.branchID);
      axios({
        method: "post",
        url: this.domain + "/table/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.tableList = response.data.table;
            this.selected = this.tableList;
          } else {
            console.log("no list get");
            this.tableList = [];
            this.selected = this.tableList;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleClick(item) {
      this.branchName = item.name;
      this.branchID = item.branch_id;
      this.branchURL = item.branch_url;

      this.dialog = true;
      this.getAllTable();
    },

    handleEditTable(item) {
      this.tNumber = item.number;
      this.tSeats = item.seats;
      this.selectTable = item.table_id;
      this.title = "Edit";
      this.openDialog();
    },

    openDialog() {
      if (this.title == "Add") {
        this.$v.$reset();
        this.tNumber = "";
        this.tSeats = "";
        this.dialogTable = true;
      } else {
        this.$v.$reset();
        this.dialogTable = true;
      }
    },

    openDialogQR() {
      this.dialogQR = true;
    },

    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteTable() {
      const params = new URLSearchParams();
      params.append("delete", "done");
      params.append("table_id", this.selectTable);
      axios({
        method: "post",
        url: this.domain + "/table/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.dialogTable = false;
            this.text = "Successfully delete";
            this.snackbar = true;
            this.snackbarColor = "success";
            this.dialogDelete = false;
            this.getAllTable();
            this.getBranchAndTableNum();
            this.sendNotication(this.branchID);
          } else {
            this.text = "Please try again later";
            this.snackbar = true;
            this.snackbarColor = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dialogAction() {
      if (this.title == "Add") {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("addTable", "done");
          params.append("branch_id", this.branchID);
          params.append("number", this.tNumber);
          params.append("seats", this.tSeats);

          axios({
            method: "post",
            url: this.domain + "/table/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialogTable = false;
                this.text = "Successfully add";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllTable();
                this.getBranchAndTableNum();
                this.sendNotication(this.branchID);
              } else if (response.data.status == "3") {
                this.text = "Please use another table number";
                this.snackbar = true;
                this.snackbarColor = "error";
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("editTable", "done");
          params.append("table_id", this.selectTable);
          params.append("branch_id", this.branchID);
          params.append("number", this.tNumber);
          params.append("seats", this.tSeats);
          axios({
            method: "post",
            url: this.domain + "/table/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialogTable = false;
                this.text = "Successfully edit";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllTable();
                this.getBranchAndTableNum();
                this.sendNotication(this.branchID);
              } else if (response.data.status == "3") {
                this.text = "Please use another table number";
                this.snackbar = true;
                this.snackbarColor = "error";
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
  },
};
</script>
<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Adjust the number of columns here */
  grid-gap: 10px; /* Adjust the gap between grid items */
}

.grid-item {
  background-color: #ccc;
  padding: 20px;
  text-align: center;
}
</style>