<template>
  <div>
    <base-card v-if="item == 5">
      <v-card-text>
        <v-card-text>
          <div class="mb-5"><span class="text-h6">Tax</span></div>
          <v-row>
            <v-col cols="12" lg="6"
              ><v-btn
                small
                color="primary"
                @click="(title = 'Add'), openDialog()"
                dark
              >
                Add Tax
              </v-btn></v-col
            >
            <v-col cols="12" lg="6"
              ><v-text-field
                class="mt-n5"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="taxList"
            :search="search"
            @click:row="handleClick"
          >
          </v-data-table>
        </v-card-text>
      </v-card-text>
    </base-card>
    <v-dialog v-model="dialog" width="400">
      <base-card>
        <v-card-text class="mt-3">
          <div class="mb-5">
            <span class="text-h6">{{ title }} Tax</span>
          </div>
          <v-text-field
            prepend-icon="mdi-note-text"
            label="Tax Name"
            v-model="tName"
            :error-messages="tNameErrors"
            required
            @input="$v.tName.$touch()"
            @blur="$v.tName.$touch()"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-percent-box"
            label="Tax Rate(%)"
            v-model="tRate"
            :error-messages="tRateErrors"
            type="number"
            required
            @input="$v.tRate.$touch()"
            @blur="$v.tRate.$touch()"
          ></v-text-field>
          <v-radio-group v-model="taxType" mandatory>
            <template v-slot:label>
              <div>Tax Type</div>
            </template>

            <template v-slot:append>
              <v-tooltip left max-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span
                  >Tax mean need to pay govorment and charge means extra earning
                  for merchant
                </span>
              </v-tooltip>
            </template>
            <v-radio label="Tax" value="1"> </v-radio>
            <v-radio label="Charge" value="0"></v-radio>
          </v-radio-group>

          <v-select
            v-model="selectBranch"
            :items="branchList"
            multiple
            chips
            prepend-icon="mdi-store"
            item-text="name"
            item-value="branch_id"
            label="Select Branch"
            :error-messages="selectBranchErrors"
            required
            @input="$v.selectBranch.$touch()"
            @blur="$v.selectBranch.$touch()"
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggleForBranch">
                <v-list-item-action>
                  <v-icon :color="selectBranch.length > 0 ? 'primary' : ''">
                    {{ iconForBranch }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
          <v-select
            v-model="selectDining"
            :items="diningList"
            multiple
            chips
            prepend-icon="mdi-silverware-fork-knife"
            item-text="name"
            item-value="dining_id"
            label="Select Dining Option"
            :error-messages="selectDiningErrors"
            required
            @input="$v.selectDining.$touch()"
            @blur="$v.selectDining.$touch()"
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggleForDining">
                <v-list-item-action>
                  <v-icon :color="selectDining.length > 0 ? 'primary' : ''">
                    {{ iconForDining }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="title == 'Edit'"
            @click="dialogDelete = true"
            text
            icon
            color="red lighten-2"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="load" @click="dialogAction()" text> save </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-subtitle-1"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" @click="deleteTax" text>OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, between } from "vuelidate/lib/validators";

export default {
  props: {
    item: {
      type: Number,
      default: "",
    },
  },
  mixins: [validationMixin],

  validations: {
    tName: { required },
    tRate: { required, between: between(1, 100) },
    selectBranch: { required },
    selectDining: { required },
  },

  data: () => ({
    domain: BASEURL,
    syncDomain: BASESYNCURL,
    snackbar: false,
    text: "",
    snackbarColor: "",
    timeout: 1900,
    companyID: "",
    diningList: [],
    taxList: [],
    branchList: [],
    tName: "",
    tRate: "",
    load: false,
    selectBranch: "",
    selectDining: "",
    selectTaxID: "",
    taxType: null,
    title: "",
    dialog: false,
    search: "",
    dialogDelete: false,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: false,
      },
      {
        text: "Tax rate(%)",
        align: "end",
        value: "tax_rate",
        sortable: false,
      },
    ],
  }),

  computed: {
    tNameErrors() {
      const errors = [];
      if (!this.$v.tName.$dirty) return errors;
      !this.$v.tName.required && errors.push("Tax name is required");
      return errors;
    },

    tRateErrors() {
      const errors = [];
      if (!this.$v.tRate.$dirty) return errors;
      !this.$v.tRate.required && errors.push("Tax rate is required");
      !this.$v.tRate.between && errors.push("Tax rate must between 1 - 100 %");

      return errors;
    },
    selectBranchErrors() {
      const errors = [];
      if (!this.$v.selectBranch.$dirty) return errors;
      !this.$v.selectBranch.required &&
        errors.push("Please select the branch for the tax");
      return errors;
    },

    selectDiningErrors() {
      const errors = [];
      if (!this.$v.selectDining.$dirty) return errors;
      !this.$v.selectDining.required &&
        errors.push("Please select which dining option for the tax");
      return errors;
    },

    getAllBranchID() {
      var array = [];
      for (let i = 0; i < this.branchList.length; i++) {
        array.push(this.branchList[i].branch_id);
      }
      return array;
    },

    selectAllBranch() {
      return this.selectBranch.length === this.getAllBranchID.length;
    },

    selectSomeBranch() {
      return this.selectBranch.length > 0 && !this.selectAllBranch;
    },

    iconForBranch() {
      if (this.selectAllBranch) return "mdi-close-box";
      if (this.selectSomeBranch) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    getAllDiningID() {
      var array = [];
      for (let i = 0; i < this.diningList.length; i++) {
        array.push(this.diningList[i].dining_id);
      }
      return array;
    },

    selectAllDining() {
      return this.selectDining.length === this.getAllDiningID.length;
    },

    selectSomeDining() {
      return this.selectDining.length > 0 && !this.selectAllDining;
    },

    iconForDining() {
      if (this.selectAllDining) return "mdi-close-box";
      if (this.selectSomeDining) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllTax();
  },
  methods: {
    getAllTax() {
      const params = new URLSearchParams();
      params.append("getAllTax", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/tax/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.taxList = response.data.tax;
          } else {
            console.log("no list get");
            this.taxList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllDiningOption() {
      const params = new URLSearchParams();
      params.append("getAllDiningOption", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/dining/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.diningList = response.data.dining;
          } else {
            console.log("no list get");
            this.diningList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          } else {
            console.log("no list get");
            this.branchList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    toggleForBranch() {
      this.$nextTick(() => {
        if (this.selectAllBranch) {
          this.selectBranch = [];
        } else {
          this.selectBranch = this.getAllBranchID.slice();
        }
      });
    },

    toggleForDining() {
      this.$nextTick(() => {
        if (this.selectAllDining) {
          this.selectDining = [];
        } else {
          this.selectDining = this.getAllDiningID.slice();
        }
      });
    },

    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBranchForNotification(tax_id) {
      const params = new URLSearchParams();
      params.append("getBranchForNotification", "done");
      params.append("tax_id", tax_id);

      axios({
        method: "post",
        url: this.domain + "/tax/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var branchList = response.data.tax;
            for (let i = 0; i < branchList.length; i++) {
              this.sendNotication(branchList[i].branch_id);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteTax() {
      const params = new URLSearchParams();
      params.append("deleteTax", "done");
      params.append("tax_id", this.selectTaxID);
      axios({
        method: "post",
        url: this.domain + "/tax/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.dialog = false;
            this.text = "Successfully delete";
            this.snackbar = true;
            this.snackbarColor = "success";
            this.dialogDelete = false;
            this.getAllTax();
            this.getBranchForNotification(this.selectTaxID);
          } else {
            this.text = "Please try again later";
            this.snackbar = true;
            this.snackbarColor = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dialogAction() {
      this.load = true
      if (this.title == "Add") {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("addTax", "done");
          params.append("company_id", this.companyID);
          params.append("tax_type", this.taxType);
          params.append("array_branch_id", JSON.stringify(this.selectBranch));
          params.append("array_dining_id", JSON.stringify(this.selectDining));
          params.append("name", this.tName);
          params.append("tax_rate", this.tRate);

          axios({
            method: "post",
            url: this.domain + "/tax/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully add";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.load = false
                this.getAllTax();
                for (let i = 0; i < this.selectBranch.length; i++) {
                  this.sendNotication(this.selectBranch[i]);
                }
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
                this.load = false
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("editTax", "done");
          params.append("name", this.tName);
          params.append("tax_rate", this.tRate);
          params.append("tax_type", this.taxType);
          params.append("array_branch_id", JSON.stringify(this.selectBranch));
          params.append("array_dining_id", JSON.stringify(this.selectDining));
          params.append("tax_id", this.selectTaxID);

          axios({
            method: "post",
            url: this.domain + "/tax/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully edit";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.load = false
                this.getAllTax();
                for (let i = 0; i < this.selectBranch.length; i++) {
                  this.sendNotication(this.selectBranch[i]);
                }
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
                this.load = false
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    handleClick(item) {
      this.tName = item.name;
      this.tRate = item.tax_rate;
      this.selectTaxID = item.tax_id;
      this.taxType = item.type.toString();
      this.getBranchDiningForTax();
      this.title = "Edit";
      this.openDialog();
    },

    getBranchDiningForTax() {
      const params = new URLSearchParams();
      params.append("getBranchDiningForTax", "done");
      params.append("tax_id", this.selectTaxID);

      axios({
        method: "post",
        url: this.domain + "/tax/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.selectBranch = response.data.branch;
            this.selectDining = response.data.dining;
          } else {
            console.log("no list get");
            this.selectBranch = "";
            this.selectDining = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openDialog() {
      this.getCompanyBranch();
      this.getAllDiningOption();
      if (this.title == "Add") {
        this.$v.$reset();
        this.tName = "";
        this.tRate = "";
        this.selectDining = "";
        this.selectBranch = "";
        this.dialog = true;
      } else {
        this.$v.$reset();
        this.dialog = true;
      }
    },
  },
};
</script>
<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>