<template>
  <div>
    <base-card v-if="item == 0">
      <v-card-text>
        <v-card-text>
          <div class="mb-5"><span class="text-h6">Account</span></div>
          <v-text-field
            v-model="uName"
            label="User Name"
            :error-messages="uNameErrors"
            required
            @input="$v.uName.$touch()"
            @blur="$v.uName.$touch()"
          />
          <v-text-field
            v-model="cName"
            label="Company Name"
            :error-messages="cNameErrors"
            required
            @input="$v.cName.$touch()"
            @blur="$v.cName.$touch()"
            disabled 
          />
          <v-text-field label="Email" disabled v-model="email" readonly>
            <!-- <template v-slot:append>
              <v-btn
                color="primary"
                @click="(securityDialog = true), (title = 'email')"
                text
                class="ma-0"
              >
                Edit
              </v-btn>
            </template> -->
          </v-text-field>
          <v-text-field
            v-model="fakePassword"
            type="password"
            label="Password"
            readonly
            required
          >
            <template v-slot:append>
              <v-btn
                color="primary"
                @click="(securityDialog = true), (title = 'password')"
                text
                class="ma-0"
              >
                Edit
              </v-btn>
            </template>
          </v-text-field>
        </v-card-text>
        <v-divider class="mt-12" />
        <v-card-actions>
          <v-btn text @click="accountInfo"> Cancel </v-btn>
          <v-spacer />
          <v-btn color="primary" @click="updateInfo" text> Save </v-btn>
        </v-card-actions>
      </v-card-text>
    </base-card>
    <v-dialog v-model="securityDialog" width="400">
      <base-card>
        <v-card-text class="mt-3">
          <div class="mb-5"><span class="text-h6">Security</span></div>
          <div class="mb-7">
            <span class="text-body-2"
              >Please enter your password to proceed {{ title }} change.</span
            >
          </div>

          <v-text-field
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            label="Password"
            v-model="checkPassword"
            @click:append="show = !show"
            :error-messages="checkPasswordErrors"
            required
            @input="$v.checkPassword.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="openEditDialog"> continue </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>
    <v-dialog v-model="editDialog" width="400">
      <base-card>
        <v-card-text class="mt-3">
          <div class="mb-5">
            <span class="text-h6">Change {{ title }}</span>
          </div>
          <div v-if="title == 'email'">
            <div class="mb-7">
              <span class="text-body-2"
                >After changing your email please use new email to login the
                portal</span
              >
            </div>
            <v-text-field
              label="Email"
              v-model="eEmail"
              :error-messages="eEmailErrors"
              required
              @input="$v.eEmail.$touch()"
              @blur="$v.eEmail.$touch()"
            ></v-text-field>
            <v-text-field
              label="Comfirm Email"
              v-model="cEmail"
              :error-messages="cEmailErrors"
              required
              @input="$v.cEmail.$touch()"
              @blur="$v.cEmail.$touch()"
            ></v-text-field>
          </div>
          <div v-else>
            <div class="mb-7">
              <span class="text-body-2"
                >After changing your password your admin portal and the pos app
                need to use the new password to login</span
              >
            </div>
            <v-text-field
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              label="Password"
              v-model="realPassword"
              @click:append="show = !show"
              :error-messages="realPasswordErrors"
              required
              @input="$v.realPassword.$touch()"
              @blur="$v.realPassword.$touch()"
            ></v-text-field>
            <v-text-field
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              label="Comfirm Password"
              v-model="cPassword"
              @click:append="show = !show"
              :error-messages="cPasswordErrors"
              required
              @input="$v.cPassword.$touch()"
              @blur="$v.cPassword.$touch()"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updatePassAndEmail" text> Save </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";

export default {
  props: {
    item: {
      type: Number,
      default: "",
    },
  },
  mixins: [validationMixin],

  validations: {
    uName: { required },
    eEmail: {
      required,
      email,
      exist(value) {
        if (value === "") return true;
        return new Promise((resolve, reject) => {
          const params = new URLSearchParams();
          params.append("checkEmailExistForUpdate", "done");
          params.append("email", this.eEmail);
          params.append("user_id", this.userID);

          axios({
            method: "post",
            url: this.domain + "/user/index.php",
            data: params,
          })
            .then((response) => {
              value = 2;
              const email = response.data.status;
              resolve(value === email);
            })
            .catch(() => reject(false));
        });
      },
    },
    cEmail: {
      sameAsEmail: sameAs("eEmail"),
    },
    realPassword: {
      required,
      minLength: minLength(6),
    },
    cPassword: {
      sameAsPassword: sameAs("realPassword"),
    },
    checkPassword: { required },

    cName: { required },
  },

  data: () => ({
    domain: BASEURL,
    show: false,
    snackbar: false,
    text: "",
    snackbarColor: "",
    timeout: 1900,
    title: "",
    uName: "",
    cName: "",
    email: "",
    eEmail: "",
    cEmail: "",
    checkPassword: "",
    realPassword: "",
    cPassword: "",
    securityDialog: false,
    editDialog: false,
    editPasswordDialog: false,
    fakePassword: "zsdfghw",
    userID: "",
    companyID: "",
  }),

  computed: {
    uNameErrors() {
      const errors = [];
      if (!this.$v.uName.$dirty) return errors;
      !this.$v.uName.required && errors.push("Name is required.");
      return errors;
    },
    eEmailErrors() {
      const errors = [];
      if (!this.$v.eEmail.$dirty) return errors;
      !this.$v.eEmail.email && errors.push("Must be valid email");
      !this.$v.eEmail.required && errors.push("Email is required");
      !this.$v.eEmail.exist && errors.push("Email already exists");
      return errors;
    },

    cEmailErrors() {
      const errors = [];
      if (!this.$v.cEmail.$dirty) return errors;
      !this.$v.cEmail.sameAsEmail && errors.push("Your email is not match");
      return errors;
    },
    realPasswordErrors() {
      const errors = [];
      if (!this.$v.realPassword.$dirty) return errors;
      !this.$v.realPassword.required && errors.push("Password is required.");
      !this.$v.realPassword.minLength &&
        errors.push("Password must at least 6 character.");
      return errors;
    },

    cPasswordErrors() {
      const errors = [];
      if (!this.$v.cPassword.$dirty) return errors;
      !this.$v.cPassword.sameAsPassword &&
        errors.push("Your password is not match");
      return errors;
    },

    checkPasswordErrors() {
      const errors = [];
      if (!this.$v.checkPassword.$dirty) return errors;
      !this.$v.checkPassword.required && errors.push("Password is required.");
      return errors;
    },

    cNameErrors() {
      const errors = [];
      if (!this.$v.cName.$dirty) return errors;
      !this.$v.cName.required && errors.push("Business Name is required.");
      return errors;
    },
  },

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.userID = decryptedData.user_id;
    this.companyID = decryptedData.company_id;
    this.accountInfo();
  },
  methods: {
    accountInfo() {
      const params = new URLSearchParams();
      params.append("getAccInfo", "done");
      params.append("user_id", this.userID);

      axios({
        method: "post",
        url: this.domain + "/user/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.uName = response.data.user[0].name;
            this.cName = response.data.user[0].cName;
            this.email = response.data.user[0].email;
          } else {
            console.log("No acc info can get");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openEditDialog() {
      this.$v.checkPassword.$touch();

      if (!this.$v.checkPassword.$invalid) {
        //checking
        const params = new URLSearchParams();
        params.append("login", "done");
        params.append("email", this.email);
        params.append("password", this.checkPassword);

        axios({
          method: "post",
          url: this.domain + "/user/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.securityDialog = false;
              this.editDialog = true;
              this.$v.checkPassword.$reset();
              this.checkPassword = "";
            } else {
              this.text = "Please make sure you enter correct password";
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    updateInfo() {
      this.$v.uName.$touch();
      this.$v.cName.$touch();

      if (!this.$v.uName.$invalid && !this.$v.cName.$invalid) {
        const params = new URLSearchParams();
        params.append("updateInfo", "done");
        params.append("user_id", this.userID);
        params.append("company_id", this.companyID);
        params.append("name", this.uName);
        params.append("company_name", this.cName);

        axios({
          method: "post",
          url: this.domain + "/user/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.text = "Successfully edit";
              this.snackbar = true;
              this.snackbarColor = "success";
              this.accountInfo();
            } else {
              this.text = "Please make sure you fill in the form correctly";
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    updatePassAndEmail() {
      if (this.title == "email") {
        this.$v.eEmail.$touch();
        this.$v.cEmail.$touch();
        if (!this.$v.eEmail.$invalid && !this.$v.cEmail.$invalid) {
          const params = new URLSearchParams();
          params.append("updateUserEmail", "done");
          params.append("user_id", this.userID);
          params.append("email", this.eEmail);

          axios({
            method: "post",
            url: this.domain + "/user/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.editDialog = false;
                this.text = "Successfully edit";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.accountInfo();
              } else {
                this.text = "Please make sure your information is valid";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.$v.realPassword.$touch();
        this.$v.cPassword.$touch();
        if (!this.$v.realPassword.$invalid && !this.$v.cPassword.$invalid) {
          const params = new URLSearchParams();
          params.append("updateUserPassword", "done");
          params.append("user_id", this.userID);
          params.append("password", this.realPassword);

          axios({
            method: "post",
            url: this.domain + "/user/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.editDialog = false;
                this.text = "Successfully edit";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.accountInfo();
              } else {
                this.text = "Please make sure your information is valid";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
  },
};
</script>